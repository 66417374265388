<template>
  <div class="site-settings">
    <b-card>
      <template>
        <form-generator
          :elements="elements"
          :data="data"
          :handleUpdate="handleUpdate"
        />
        <div>
          <div v-html="updated_html"></div>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import FormGenerator from "@/components/form-generator.vue";
import api from "@/api";

export default {
  components: { FormGenerator },
  props: {
    data: Object
  },
  name: "supportedit",
  data() {
    return {
      loading: false,
      saving: false,
      updated_html: ""
    };
  },
  mounted() {
    this.show();
  },
  computed: {
    elements() {
      return [
        {
          key: "html",
          label: "",
          type: "html",
          attrs: {
            customToolbar: [
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }],
              [
                { align: "left" },
                { align: "center" },
                { align: "right" },
                { align: "justify" }
              ],
              [{ color: "color-picker" }]
            ]
          }
        },
        {
          type: "button-group",
          className: "float-right",
          elements: [
            {
              type: "button",
              text: "save",
              className: "px-5 float-right mt-3",
              attrs: {
                loading: this.saving
              },
              events: {
                click: this.update
              }
            }
          ]
        }
      ];
    }
  },
  methods: {
    handleUpdate(el, value) {
      this.updated_html = value;
    },

    async show() {
      api.get("/supports").then(res => {
        const data = res.data;
        this.data = data.data;
      });
    },
    async update() {
      this.loading = true;
      this.saving = true;
      try {
        api.post("/supports", {
          html: this.updated_html
        });
      } catch (err) {
        console.error(err);
      }

      this.saving = false;
      this.loading = false;
    }
  }
};
</script>

<style></style>
